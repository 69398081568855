
.fullwith {
  width: 100%;
}
.halfwidth {
  width: 50%;
  @media (max-width: 360px) {
    width: 80%;
  }
}

.wh--32 {
  height: 32px;
  width: 32px;
}

.wh--40 {
  height: 40px;
  width: 40px;
}
.wh--45 {
  height: 45px;
  width: 45px;
}
.wh--50 {
  height: 50px;
  width: 50px;
}
.wh--60 {
  height: 60px;
  width: 60px;
}

.h--32 {
  height: 32px !important;
}
.h--40 {
  height: 40px !important;
}
.h--45 {
  height: 45px !important;
}
.h--50 {
  height: 50px !important;
}
.h--60 {
  height: 60px !important;
}