.guarantee__wrapper {
    display: grid;
    grid-template-columns: 1fr 50%;
    gap: 16px;

    &--list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    &--options {
        background-color: $c-background;
        padding: 16px;
        border-radius: 8px;
        // height: 90vh;
    }

    &--composants {
        padding: 24px 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &--infos {
        padding: 24px 0;

        .form {
            background-color: $c-white;
            margin-top: 16px;
            border-radius: 8px;
            padding: 8px;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .list {
            padding-top: 8px;

            .item {
                border-bottom: 1px solid $c-gray;
                padding: 16px 0;
                &:last-child {
                    border-bottom: 0;
                    padding-bottom: 0;
                }

                .head {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 16px;
                    margin-bottom: 8px;

                    h4 {
                        margin-bottom: 0;
                        font-size: clamp(12px, 3vw, 14px);
                        font-weight: 600;
                        color: $c-black;
                    }
                }

                p {
                    color: $c-gray;
                }
            }
        }
    }
}

.card__guarantee {
    background-color: rgba($c-gray, .06);
    padding: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border: 2px solid transparent;
    width: 100%;
    transition: all ease-in .4s;

    span {
        font-size: clamp(14px, 3vw, 16px);
    }


    &:hover,
    &.active {
        border: 2px solid $c-primary;
        transition: all ease-in .4s;
    }

}