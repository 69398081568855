.coverage-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin: auto;
  .coverage-benefits-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    .coverage__input {
      display: flex;
      flex-direction: column;
      border: 1px solid $c-border;
      padding: 8px 16px;
      border-radius: 4px;
      width: 100%;
      svg {
        cursor: pointer;
      }
      h3 {
        font-size: 16px;
      }
      &--header {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        align-items: center;
        margin-bottom: 16px;
      }
      &--info {
        width: 100%;
        display: flex;
        gap: 8px;
        margin-bottom: 16px;
        flex-wrap: wrap;
        .input__wrapper:not(:first-child),
        .select__wrapper {
          flex: 1;
        }
        .input__wrapper:first-child {
          width: 70px;
        }
      }
      &--limits {
        width: 100%;
        display: flex;
        gap: 8px;
        margin-top: 16px;
        align-items: flex-end;
        flex-wrap: wrap;
        .input__wrapper,
        .select__wrapper {
          flex: 1;
          max-width: 250px;
        }
      }
      &--actions {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
      &--row {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 16px;
      }
    }
  }
}
