.drawer__area {
  &--head {
    display: flex;
    align-items: center;

    .icon--close {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e5e8ed;
      border-radius: 50px;
      cursor: pointer;
      margin-right: 16px;
    }

    span {
      font-size: clamp(16px, 3vw, 24px);
      font-weight: 500;
      color: $c-dark;
    }
  }

  .ant-drawer-content-wrapper {
    width: 65% !important;

    @media (max-width: 1199px) {
      width: 75% !important;
    }

    @media (max-width: 1024px) {
      width: 80% !important;
    }
    @media (max-width: 991px) {
      width: 95% !important;
    }

    .ant-drawer-content {
      border-radius: 16px 0 0 16px;
      background-color: #f8faff;
    }
  }
}

.ant-drawer-content-wrapper {
  // width: auto !important;
}
.drawer__custom {
  .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: space-between;
    align-items: center;
    min-width: 0;
    min-height: 0;
    width: 100%;
  }

  .ant-drawer-title {
    font-size: clamp(16px, 3vw, 24px) !important;
  }
}
