.dropdown__wrap {
  &--head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
}
.ant-dropdown{
  z-index: 9999;
}

.ant-dropdown-menu-item {
  gap: 4px;
  .ant-dropdown-menu-title-content {
    font-size: clamp(12px, 3vw, 16px);
    color: $c-text;
    svg {
      color: $c-gray-dark;
    }
  }
}
