.interaction {
  max-height: 70dvh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 4px 0;
}

.info {
  &__header {
    text-align: right;
    padding: 24px 0 40px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    label {
      color: $c-gray;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 24px;
  }

  &__block {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px 0;
    >div {
      display: flex;
      gap: 4px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $c-border;
    }
    &--child {
      display: flex;
      gap: 4px;
    }
  }
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .qualify {
      padding-top: 20px;
      border-top: 1px solid $c-border;
    }
  }

  button {
    width: 100%;
    height: 70px;
  }
}

.qualification {
  &-tab {
    &__item {
      background-color: $c-white;
      padding: 16px;
      font-size: 16px;
      line-height: 22px;
      border: 1px solid $c-border;

      &--active {
        color: $c-primary;
        font-weight: 700;
      }

      &:first-child {
        border-top-left-radius: 16px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__card {
    .card__wrapper {
      border-top-left-radius: 0 !important;
    }
  }
}