.chart--area {
  margin-bottom: 8px;

  &--content {
    height: max-content;
    overflow: hidden;
    position: relative;
    @media (max-width: 700px) {
      padding-bottom: 24px;
    }
  }

  article {
    height: 465px !important;
  }
}

.theme-dark {
  background-color: red;
}

.apexcharts-tooltip {
  background-color: $c-white !important;
  color: $c-gray;
}

.apexcharts-svg {
  background: transparent !important;
}

.apexcharts-canvas {
  background: $c-dark-2 !important;
}
