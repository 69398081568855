.home__container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 84px);
  font-family: "Barlow", sans-serif !important;

  .connect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    border: 1px solid $c-blue-dark2;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: $c-blue-dark2;
    width: 50vw;
    height: calc(calc(100vh - 84px) * 0.5);
    img {
      height: 60px;
      width: 60px;
    }
    p {
      color: $c-white;
      font-size: $font20;
      font-weight: 500;
      text-align: center;
    }
  }
}
