.grid--1 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  grid-gap: 16px;
}

.grid--2 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  grid-gap: 16px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.grid--3 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
  grid-gap: 16px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.grid--4 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
  grid-gap: 16px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.d-flex {
  display: flex;
  align-items: center;
  gap: 8px;
}

.flex--column {
  flex-direction: column;
}

.flex--wrap {
  flex-wrap: wrap;
}

.flex--grow-1 {
  flex-grow: 1;
}

.align--center {
  align-items: center;
}

.align--start {
  align-items: flex-start;
}

.align--end {
  align-items: flex-end;
}

.justify--end {
  justify-content: flex-end;
}

.justify--between {
  justify-content: space-between;
}

.justify--start {
  justify-content: flex-start;
}

.justify--center {
  justify-content: center;
}
