.commission-statements {
  &-filter {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 24px;
    align-items: center;
  }
  .ant-upload-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }
  .select__wrapper
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    min-width: 200px;
  }
  &-filter {
    .select__wrapper
      .ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: $c-white;
      min-width: 128px;
    }
  }
  &-empty {
    font-size: 14px;
    display: flex;
    justify-content: center;
    color: $c-text-sec;
  }
  &-row {
    font-size: 14px;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background-color: $c-primary-background;
  }
  &-header {
    font-size: 16px;
    font-weight: 700;
    padding: 4px 8px;
  }
}
