.c-gray {
    color: $c-gray;
}

.c-primary {
    color: $c-primary;
}

.c-secondary {
    color: $c-secondary;
}

.c-text {
    color: $c-text;
}

.c-white {
    color: $c-white;
}