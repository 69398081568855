.box--wrap {
  border: 1px solid $c-blue-dark2;
  background-color: $c-blue-dark3;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  z-index: 0;
  height: 465px !important;
}

.box--blur {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 8px;
  height: 465px !important;
  z-index: 1;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba($c-dark-2, 0.5);
}
