.quote {
  margin-top: 24px;
  padding: 4px 24px 4px 32px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 32px;
  font-weight: 500;
  &--icon {
    margin-right: 24px;
  }
  &--description {
    width: 80%;
    margin: 0;
  }
  &--warning {
    background: #fffae9;
    color: #b39d53;
  }
  &--success {
    background: #dbf5e7;
    color: #41ad6a;
  }
}
