.custom--tab {
  @media (max-width: 991px) {
    margin-top: 24px;
  }

  & .ant-tabs-tab {
    color: $c-gray-light;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: clamp(14px, 3vw, 16px) !important;
    background-color: rgba($c-gray, .06);
    border-bottom: 2px solid rgba($c-gray, .2);

    &:hover,
    &:focus {
      color: $c-blue-light2;
    }
  }

  & .ant-tabs-tab-active {
    background-color: rgba($c-primary, .08);
    & .ant-tabs-tab-btn {
      color: $c-blue-light2;
      
    }

    & .step-icon {
      color: $c-blue-light2;
    }

    & .step-title {
      color: $c-blue-light2;
    }
  }

  & .ant-tabs-ink-bar {
    background: $c-blue-light2;
    border-radius: 2px;
  }

  & .ant-tabs-nav-list {
    justify-content: space-between;
    width: 100%;
  }

  & .ant-tabs-nav-operations {
    display: none !important;
  }

  & .ant-tabs-tabpane {
    min-height: 200px;
  }
}

.ant-tabs-nav::before {
  border-bottom: $c-white !important;
}
