.text-editor--wrapper {
  z-index: 0;

  & .ce-block__content {
    max-width: none;
  }

  & .ce-toolbar__content {
    max-width: none;
  }

  & .codex-editor__redactor {
    padding-bottom: 0 !important;
  }
}
