.timeline--interactions {
  & .ant-card-bordered {
    border: none;
  }

  & .ant-card-head {
    border: none;
    padding: 0;
  }

  & .interaction--wrapper {
    & .ant-card-body {
      border: 1px solid $c-gray-light;
      border-radius: 8px;
      background: $c-white;
    }

    &.content-false {
      & .ant-card-body {
        display: none;
      }
    }

    & .qualifier--title {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      .material-symbols {
        svg {
          width: 16px;
        }
      }

      &.UNTREATED {
        color: $c-qualifier-untreated;
      }
      &.NO_ANSWER {
        color: $c-qualifier-no-answer;
      }
      &.REFUSAL_ANSWER {
        color: $c-qualifier-refusal-answer;
      }
      &.WRONG_NUMBER {
        color: $c-qualifier-wrong-number;
      }
      &.SIGNED {
        color: $c-qualifier-signed;
      }
      &.DUPLICATE {
        color: $c-qualifier-duplicate;
      }
      &.QUOTE_SENT {
        color: $c-qualifier-quote-sent;
      }
      &.ANSWERING_MACHINE {
        color: $c-qualifier-answering-machine;
      }
      &.ARGUED {
        color: $c-qualifier-argued;
      }
      &.NON_ARGUED {
        color: $c-qualifier-non-argued;
      }
    }

    & .note--title {
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }

    & .author--name {
      color: $c-gray;
      font-weight: normal;
    }
  }

  & .dot--divider {
    display: flex;
    align-items: center;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin: 0 8px;
    background: $c-gray;
  }

  & .ant-timeline-item-head {
    border-color: $c-gray-light;
    background: $c-gray-light;
  }
}
