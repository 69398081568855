.format__area {

    &--list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;

        .item {
            width: 100%;
            padding: 8px;
            color: $c-text;
            background-color: rgba($c-white, .5);
            text-align: right;
            border-radius: 4px;
        }
    }
}
