.balances--popover {
  background-color: $c-third;
  padding-block: 2rem;
  color: white;
  font-size: $font16;
  padding: 10;
  p {
    font-size: $font20;
    font-weight: 600;
    text-align: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    .entry {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .amount {
        font-weight: 600;
      }
    }
  }
}

.ant-popover-inner {
  padding: 0;
  border-radius: 8px;
}
.ant-popover-inner-content {
  padding: 0;
  border-radius: 8px;
  max-width: 400px;
}

.ant-popover-arrow-content {
  --antd-arrow-background-color: #201f57;
}

.custom-popover {
  display: none;
  position: absolute;
  background-color: $c-grey-33;
  color: $c-white;
  padding: 8px;
  line-height: 16px;
  text-align: center;
  border-radius: 4px;
  bottom: 120%;
  left: 50%;
  transform: translate(-50%, 0);
  width: max-content;
  max-width: 300px;
  min-width: 150px;
  z-index: 10;
  white-space: normal;
  font-size: 12px;
}

.small {
  width: 100px;
}

.custom-popover p {
  margin: 0;
}

.popover-wrapper {
  position: relative;
  cursor: default;
  &:hover .custom-popover {
    display: block;
  }
}

.popover-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid $c-grey-33;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}
