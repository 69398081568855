.contact-stepper {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    &--switch {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
    }
    &--counter {
      width: 24px;
      height: 24px;
      margin-right: 24px;
      border-radius: 100px;
      background: rgba(14, 147, 229, 0.1);
      color: #0e93e5;
      border-color: rgb(209, 213, 219);
      display: flex;
      align-items: center;
      font-weight: 600;
      justify-content: center;
    }
    &--content {
      display: flex;
      flex-direction: column;
      &--title {
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        margin: 0;
        > span:first-child {
          margin-right: 16px;
        }
      }
      &--description {
        color: #a5aac2;
        margin: 0;
      }
    }
    &--switch {
      margin-left: auto;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(209, 213, 219);
    transition: all 0.3s ease-in;
    padding: 16px;
    border-radius: 10px;
    margin: auto 40px;
    gap: 16px;
    &:hover {
      box-shadow: 0 0 16px 0px #ececec;
    }
    &--radio {
      display: flex;
      align-items: center;
      &--icon {
        margin-right: 8px;
      }
      &--title {
        font-weight: 600;
      }
      &--switch {
        min-width: max-content;
        margin-left: auto;
      }
    }
    &--box {
      background: $c-background;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-radius: 8px;
      & .ant-input,
      & .ant-select-selector,
      & .ant-picker {
        background-color: $c-white !important;
        border: 1px solid $c-border !important;
      }
      &--header {
        display: flex;
        margin-bottom: 24px;
        position: relative;
        &--title {
          margin: 0;
          color: #2f3838;
          font-weight: 800;
          font-size: 16px;
        }
        &--action {
          margin-left: auto;
          cursor: pointer;
          background: #fff;
          color: #2f3838;
          font-weight: 500;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          border-radius: 100px;
          padding: 0 10px;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: -8px;
          left: 0;
          right: 0;
          border: 1px solid #e6ecf5;
          width: 100%;
          display: inline-block;
        }
      }
    }
  }
  &__status {
    padding: 0 16px;
    border-radius: 100px;
    font-weight: 600;
    font-size: 12px;
    &--success {
      background: #dbf5e7;
      color: #41ad6a;
    }
    &--warning {
      background: #ffe298;
      color: #ad9041;
    }
  }
}
