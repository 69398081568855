.table__wrapper {
  .ant-table {
    background-color: transparent;

    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
      background-color: $c-white;
    }

    .ant-table-tbody > tr {
      border-radius: 0 !important;
    }

    .ant-table-tbody > tr > td {
      border-bottom: 1.5px solid $c-background;
      color: $c-text;
      background-color: $c-white;
      padding: 16px 16px;
      font-size: clamp(12px, 3vw, 14px);
      font-weight: 400;
      white-space: nowrap;
    }

    .ant-table-tbody > tr > td:first-child {
      border-left: 1px solid $c-background;
    }
    .ant-table-tbody > tr > td:last-child {
      border-right: 1px solid $c-background;
    }
    .ant-table-thead > tr > th,
    .ant-table-thead > tr > td {
      background: $c-gray-F8 !important;
      border-bottom: none;
      color: $c-text;
      padding: 16px;
      font-weight: 500;

      &::before {
        display: none;
      }

      &:first-child {
        border-start-start-radius: 8px;
      }

      &:last-child {
        border-end-end-radius: none !important;
        border-top-right-radius: 8px;
      }
    }
    .ant-table-sticky-holder {
      background: $c-dark-2 !important;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      cursor: pointer;
      border-start-start-radius: 0px !important;
      border-end-start-radius: 0px !important;
    }

    .ant-table-expanded-row {
      &-fixed {
        width: 100% !important;
      }
      .ant-table-cell {
        background-color: rgba($c-background, 1);
      }
    }
  }

  .ant-select-selector {
    background: $c-white !important;
  }

  .select__wrapper {
    min-width: 300px;
    @media (max-width: 991px) {
      min-width: auto;
    }
  }
}

.ant-pagination {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .ant-pagination-item {
    border-color: $c-background !important;
    a {
      color: $c-text;
    }
  }

  .ant-pagination-item-link {
    color: $c-text !important;
    border-color: $c-background !important;
  }
  .ant-pagination-item-active {
    font-weight: 500;
    background: $c-background !important;
    border-color: $c-background !important;

    a {
      color: $c-primary !important;
    }
  }
}

//

.table__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $c-white;
  border-radius: 4px;
  padding: 8px 16px;
  gap: 16px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &--item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &--actions {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Modifiers
.logo--table {
  height: 32px;
}

.upload--link {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
  color: $c-primary;
  cursor: pointer;
}
