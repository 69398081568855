.empty__table {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    padding: 16px;

    img {
        height: 92px;
        width: max-content;
    }
    p {
        font-size: clamp(14px, 3vw, 16px);
        text-align: center;
        margin-bottom: 0;
    }

    h3 {
        font-size: clamp(14px, 3vw, 18px);
        text-align: center;
        margin-bottom: 8px;
    }

    .lottiefiles {
        height: 300px !important;
    }
}