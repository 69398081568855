.client-form {
  max-width: 664px;
  margin: auto;
  .form-vertical--wrapper {
    margin: 16px 0;
  }
  &__action {
    margin-top: 16px;
    display: flex;
    align-items: center;
    .button__wrapper:last-child {
      margin-left: auto;
    }
  }
  .form-vertical--content {
    margin: auto 48px;
  }
  .client-form--wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    transition: all 0.3s ease-in;
  }
}
