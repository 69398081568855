.autocomplete__wrapper {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;

  &.__error {
    .label__area {
      color: #f26262 !important;
    }
    .ant-select-selector {
      background: rgba(242, 98, 98, 0.1) !important;
    }
    .ant-select-selection-placeholder {
      color: #f26262 !important;
    }
  }

  .label__area {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 400;

    & .label__icon {
      display: flex;
      align-items: center;
    }
  }

  &--field {
    width: 100%;
  }

  & .ant-select {
    width: 100%;
    border-radius: 8px;
  }

  & .ant-select:not(.ant-select-customize-input) {
    & .ant-select-selector {
      background-color: $c-background;
      border: none;
    }
  }

  & .ant-select-selector {
    background-color: $c-background;
    border-radius: 8px;
    border: none;
    color: $c-text;
    font-weight: 600;
    font-size: 16px;

    &:focus {
      box-shadow: none;
    }

    & .ant-select-selection-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  & .ant-select-selection-overflow-item {
    & .ant-select-selection-item {
      background: $c-white !important;
      border: 1px solid $c-gray !important;
    }
  }
}

.group {
  display: flex;
  &--horizental > div {
    flex: 1 1 0%;
  }
  &__content {
    display: flex;
    align-items: center;
    &--icon {
      margin-right: 8px;
    }
    &--label {
      font-weight: 600;
    }
  }
  &__select .ant-select {
    width: 100%;
    .ant-select-selector {
      border-radius: 4px !important;
      padding: 0 8px !important;
      background: #eff3f8 !important;
      border-color: #e8e8e8 !important;
      align-items: center;
      height: 40px;
    }
    &.dark .ant-select-selector {
      background: #fff !important;
      border-color: rgb(226, 226, 226) !important;
    }
  }
}

.select--wrapper {
  & .ant-select {
    width: 100%;
  }
}
