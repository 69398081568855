.cost-tag {
  font-weight: 700;
  font-size: 16px;

  &--large {
    font-size: 24px;
    line-height: 32px;
  }

  &--medium {
    font-size: 16px;
    line-height: 24px;
  }

  &--small {
    font-size: 12px;
    line-height: 16px;
  }

  &--primary {
    color: $c-primary;
  }

  &--secondary {
    color: $c-secondary;
  }
  &--period {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $c-text-sec;
  }
}

@media (max-width: 800px) {
  .price_tag {
    font-size: 12px;
  }
}
