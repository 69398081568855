.avatar--image {
  height: 32px;
  width: 32px;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
}

.avatar--text {
  height: 32px;
  width: 32px;
  border-radius: 100px;
  background-color: $c-primary;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(12px, 3vw, 14px);
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}

.avatar--xLarge {
  height: 128px;
  width: 128px;
  font-size: clamp(24px, 3vw, 32px);
}
.avatar--large {
  height: 64px;
  width: 64px;
}

.avatar--medium {
  height: 48px;
  width: 48px;
}
.avatar--small {
  height: 32px;
  width: 32px;
}

.avatar--normal {
  border: none;
}
.avatar--bordered {
  border: 1px solid $c-primary;
}

.avatar--bg--default {
  background-color: rgba($c-primary, 1);
  color: $c-white;
}

.avatar--bg--primary {
  background-color: rgba($c-primary, 0.1);
  color: $c-primary;

  &::before {
    content: "";
    background-color: rgba($c-primary, 0.2);
    margin: auto;
    position: absolute;
    top: 5%;
    left: 0;
    right: 0;
    width: 90%;
    height: 90%;
    border-radius: 100px;
  }
}

.avatar--bg--second {
  background-color: rgba($c-red, 0.1);
  color: $c-red;

  &::before {
    content: "";
    background-color: rgba($c-red, 0.2);
    margin: auto;
    position: absolute;
    top: 5%;
    left: 0;
    right: 0;
    width: 90%;
    height: 90%;
    border-radius: 100px;
  }
}
