.select-products {
  display: flex;
  flex-direction: column;
  gap: 32px;
  &__header {
    display: flex;
    column-gap: 8px;
    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
    }
  }
  .ant-alert {
    padding: 12px 24px;
    column-gap: 36px;
    font-size: 12px;
    line-height: 16px;
    border: none;
    background-color: $c-primary-light;
  }
  &__cards {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__card,
  &__card--selected {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    padding: 16px 36px;
    column-gap: 36px;
    row-gap: 4px;
    cursor: pointer;
    background-color: $c-white;
  }
  &__card {
    border: 2px solid $c-border;
    &--icon {
      width: 132px;
      height: 72px;
      object-fit: contain;
    }
    &--text {
      flex: 1;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      span {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $c-gray;
      }
    }
    &--check {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $c-border;
      border-radius: 50%;
      .material-symbols {
        display: none;
      }
    }
    &--selected {
      border: 2px solid $c-success;
      & .select-products__card--check {
        background-color: $c-success;
        .material-symbols {
          display: block;
        }
      }
    }
  }
}

.locked {
  & .select-products__cards {
    pointer-events: none;
  }
}
