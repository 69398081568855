.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.65) !important;
}

.supporting--modal {
  width: 50%;

  @media (max-width: 991px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 95%;
  }
}

.custom__modal {
  width: 40% !important;

  @media (max-width: 1199px) {
    width: 60% !important;
  }

  @media (max-width: 991px) {
    width: 80% !important;
  }

  @media (max-width: 600px) {
    width: 95% !important;
  }

  .ant-modal-content {
    background-color: white;
    border-radius: 16px;
  }

  .ant-modal-header {
    .ant-modal-title {
      font-size: clamp(18px, 3vw, 24px);
    }
  }

  .ant-modal-body {
    .input__wrapper {
      width: 100%;
    }

    .btn--grow {
      .button__wrapper {
        width: 100%;
      }

      button {
        width: 100%;
      }
    }
  }

  .ant-modal-close {
    color: $c-gray2;
  }

  .footer {
    margin-top: 24px;
  }
}

.custom--mini--modal {
  .ant-modal-body {
    padding: 24px 0 0;
  }

  .ant-modal-content {
    background-color: $c-dark-2;
    border-radius: 8px;
  }

  .ant-modal-close {
    color: $c-gray;

    .ant-modal-close-x {
      width: 50px;
      height: 40px;
    }
  }

  .footer {
    margin-top: 24px;
  }
}

.options--modal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    text-align: center;
  }

  .options__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    gap: 1rem;

    .option__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding: 2rem;
      border-radius: 8px;
      border: 1px solid $c-purple2;
      background-color: $c-white;
      width: 200px;
      height: 180px;
    }

    .selected {
      background-color: $c-main;
    }

    img {
      height: 50px;
      width: 50px;
    }
  }
}

.modal-container {
  .ant-modal {
    &-content {
      border-radius: 8px;
      padding: 16px;
    }

    &-header {
      margin-bottom: 0;
    }

    &-title {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        color: $c-text;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
      }

      &:has(span:empty) {
        display: none;
      }
    }

    &-body {
      padding: 16px 0;
    }

    &-footer {
      margin-top: 0;
      display: flex;
      gap: 4px;
      justify-content: space-between;
      align-items: center;
    }
  }

  .footer {
    margin-top: 24px;
  }
}

.custom__drawer {
}
