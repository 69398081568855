.profile__area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid $c-gray-light;
  border-radius: 8px;
  cursor: pointer;

  &--media {
    margin-right: 2px;
  }

  &--content {
    h4 {
      font-size: clamp(12px, 3vw, 14px);
      font-weight: 600;
      margin-bottom: 4px;
    }
    h6 {
      font-size: clamp(8px, 3vw, 12px);
      font-weight: 400;
      color: $c-gray;
    }
  }

  &--icon {
    color: $c-gray;
  }
}