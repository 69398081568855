.slider {
  width: 100%;
  .slider--boundaries {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-weight: 600;
    font-size: $font16;
  }
  .ant-slider-dot {
    border-color: $c-blue-light;
    background-color: $c-blue-light;
  }
  .ant-slider:hover .ant-slider-rail {
    background-color: $c-blue-light;
  }
  .ant-slider-rail {
    background-color: $c-blue-light;
  }
  .ant-slider-track {
    background-color: $c-main !important;
  }

  .ant-slider-handle.ant-tooltip-open {
    border-color: $c-purple;
    background-color: $c-purple;
  }

  .ant-slider-dot-active {
    border-color: $c-purple;
    background-color: $c-main;
  }
}

.custom--progress {
  .ant-progress-inner {
    background-color: $c-white;
  }
  .ant-progress-bg {
    background-color: $c-forth;
  }
}

.slider-dot--wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 18px;

  & .slider-dot--container {
    height: 3px;
    width: 100%;
    background: $c-border;
    display: flex;
    align-items: center;

    & .slider-dot--content {
      display: flex;
      justify-content: space-between;
      width: inherit;
      align-items: center;

      & .input-row {
        display: flex;
        align-items: center;
        height: 18px;

        & .input-radio {
          -webkit-appearance: none;
          appearance: none;
          margin: 0;
          font: inherit;
          display: grid;
          place-content: center;
          background: $c-gray-light3;
          border: 1px solid $c-white;
          border-radius: 50%;
          width: 12px;
          height: 12px;
          cursor: pointer;

          &:checked::before {
            transform: scale(1);
          }

          &::before {
            content: "";
            width: 18px;
            height: 18px;
            background: $c-primary;
            border: 3px solid $c-white;
            border-radius: 50%;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
            transform: scale(0);
            transition: 120ms transform ease-in-out;
          }
        }
      }
    }
  }

  & .ant-radio-wrapper {
    margin: 0;

    &:hover {
      border-color: red !important;
    }
  }

  & .ant-radio-wrapper {
    & .ant-radio-inner {
      background-color: $c-border;
      border-color: $c-white;
      width: 12px;
      height: 12px;
    }
    &:not(:has(.ant-radio-checked)) {
      &:hover {
        & .ant-radio-inner {
          background-color: $c-blue-light3;
        }
      }
    }
    & .ant-radio-checked {
      & .ant-radio-inner {
        box-sizing: content-box;
        border: 3px solid $c-white;
        background-color: $c-primary;
        color: $c-primary;
        box-shadow: 0px 0px 8px rgba($c-black, 0.15);
      }
      & .ant-radio-inner:after {
        background-color: $c-primary;
      }
    }
  }
}

.slider-line--wrapper {
  & .ant-slider-track {
    background: $c-primary;
  }

  & .ant-slider-handle {
    &::after {
      box-shadow: 0 0 0 3px $c-primary;
    }

    &:focus::after,
    &:hover::after {
      box-shadow: 0 0 0 4px $c-primary;
    }
  }

  & .ant-slider-rail {
    background: $c-blue-light3;
  }
}


.ant-tooltip {
  z-index: 10;
  & .ant-tooltip-inner {
    background: transparent;
    box-shadow: none;
    color: $c-primary;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  & .ant-tooltip-arrow-content {
    box-shadow: none !important;
    background: transparent;
    --antd-arrow-background-color: none;
  }

  & .ant-tooltip-arrow {
    &::before {
      background: transparent;
    }
  }
}
