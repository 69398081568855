.label__area {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  .label__icon {
    display: flex;
  }
}

.hide--icon:empty,
.hide--label:empty,
.hide--label {
  display: none !important;
}
