.card-container {
  background: $c-white;
  border-radius: 8px;
  padding: 24px 24px;
}

.card-header {
  padding: 0 0 12px 0;
}

.card-body {
  padding: 16px 0;
}

.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 0;
  color: $c-text;
}

.card-subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $c-text-sec;
  margin-top: 8px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: $c-border;
}

.card__wrapper {
  background: $c-white;
  border-radius: 8px;
  padding: 24px;

  &--head {
    padding-bottom: 16px;
    border-bottom: 1px solid $c-border;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 16px;
    &--title {
      font-weight: 700;
      font-size: clamp(16px, 3vw, 20px);
      line-height: clamp(24px, 3vw, 38px);
      margin-bottom: 0;
    }
    &--subtitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $c-text-sec;
      margin-top: 0px;
    }

    &--actions {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: flex-end;
    }
  }

  @media (max-width: 991px) {
    padding: 16px;
  }
}

.product--selected {
  width: 100%;
  margin-top: 8px;
  & .product-info__img {
    height: 80px;
  }
}

.icon {
  object-fit: contain;
  max-height: 100px;
  max-width: 200px;
}
