.quote-template {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: $c-white;
  border-radius: 8px;
  padding: 24px 24px;

  &__header {
    display: flex;
    column-gap: 8px;
    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
    }
  }
  .ant-alert {
    padding: 12px 24px;
    column-gap: 16px;
    font-size: 12px;
    line-height: 16px;
    border: none;
    background-color: $c-yellow-light;
  }
  &__mail {
    border: 1px solid $c-border;
    padding: 16px;
    &-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 8px;
      &--space {
        padding: 0 8px 4px;
      }
      & .input__wrapper,
      & .select__wrapper {
        flex-direction: row;
        align-items: center;
        label {
          width: 150px;
          font-size: 12px;
          line-height: 14px;
          color: $c-gray;
        }
      }
      .ant-select-selection-item {
        font-size: 12px;
        line-height: 14px;
      }
      .ant-select-selection-overflow-item-rest {
        .ant-select-selection-item {
          background-color: transparent !important;
          border: none !important;
        }
      }
    }
    &-label {
      border-top: 1px solid $c-border;
      padding: 8px 0 16px 0;
      font-size: 12px;
      line-height: 14px;
      color: $c-gray;
    }
    &-body {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding: 16px 32px;
      background-color: $c-background;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      &--link {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: $c-primary;
        span {
          font-size: 24px;
          line-height: 28px;
        }
        a {
          color: $c-primary;
          text-decoration: underline;
        }
        &::first-letter {
          text-decoration: none;
        }
      }
      & textarea {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        background-color: transparent;
        padding: 0;
      }
      &--divider {
        padding: 8px 0;
        color: $c-gray;
        font-size: 12px;
      }
      &--sender {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        span {
          display: block;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: $c-gray;
        }
      }
      &--icon {
        width: 150px;
        object-fit: contain;
      }
      &--telecoms {
        display: flex;
        gap: 12px;
        font-size: 12px;
        line-height: 14px;
        .left {
          font-weight: 600;
          color: $c-gray;
          & p::after {
            content: ":";
            padding-left: 12px;
            float: right;
          }
        }
        .right {
          font-weight: 400;
          color: $c-primary;
          text-decoration: underline;
        }
      }
    }
  }
  &__attachments {
    padding: 12px;
    background-color: $c-background;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    &--file {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    &--unlink {
      cursor: pointer;
      display: flex;
    }
    &--fileIcon {
      height: 25px;
      object-fit: none;
    }
    &--fileName {
      color: $c-text;
      text-transform: lowercase;
    }
    &--fileType {
      color: $c-gray;
      font-size: 12px;
      text-transform: lowercase;
    }
  }
  &__cards {
    display: flex;
    gap: 8px;
  }
  &__card {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    border-radius: 8px;
    padding: 8px 12px;
    cursor: pointer;
    background-color: $c-white;
    border: 2px solid $c-border;
    min-width: 116px;
    &--icon {
      height: 26px;
      width: 76px;
      object-fit: contain;
    }
    &--text {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      span {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $c-gray;
      }
    }
    &--cost {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: $c-primary;
    }
  }
}

.locked {
  & .quote-template__mail {
    pointer-events: none;
  }
}
