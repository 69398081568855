.radio__wrapper {
  .radio--custom {
    background-color: white;
    border: 2px solid $c-main;
    width: 100%;
    padding: 18px 16px;
    border-radius: 12px;
  }
  .radio--custom.active {
    border: 2px solid $c-main;
  }
}

.radio-group__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  flex-direction: column;

  .label__area {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;

    & .label__icon {
      display: flex;
      align-items: center;
    }
  }

  &--field {
    width: 100%;
  }

  .custom {
    background-color: $c-background;
    border-radius: 8px;
    padding: 2px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &--item {
      background-color: transparent;
      border: 1px solid $c-border;
      border-radius: 4px;
      padding: 8px 16px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      gap: 2px;

      &::before {
        content: none !important;
      }

      &:hover:not(.ant-radio-button-wrapper-disabled) {
        color: $c-primary !important;
        background-color: $c-white;
      }
    }

    &--label {
      white-space: pre-wrap;
      min-width: 180px;
      text-align: center;
      line-height: 18px;
    }

    .checked {
      background-color: $c-white;
      border: 1px solid $c-primary;
      color: $c-primary;
    }
  }
  .custom.active {
    background-color: $c-white;
    border: 1px solid $c-primary;
    color: $c-primary;
  }
  .ant-radio-checked .ant-radio-inner,
  .ant-radio-checked .ant-radio-inner:after,
  .ant-radio:hover .ant-radio-inner {
    border-color: $c-primary;
    background-color: $c-white;
    color: $c-primary;
  }

  .ant-radio-button-wrapper {
    position: relative;
    &:hover .custom-popover {
      display: block;
    }
  }
}

.radio-group {
  display: flex;
  flex-direction: column;
  &--horizental > div {
    flex: 1 1 0%;
    flex-direction: row;
  }
  &__content {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;

    &--label {
      font-weight: 600;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
    &--icon {
      display: flex;
    }
  }
  &__radio {
    display: flex;
    flex-direction: column;
    &--radio {
      display: flex !important;
      width: 100% !important;
      height: 40px !important;
      border-radius: 4px !important;
      background: #eff3f8 !important;
      border-color: #eff3f8 !important;
      > label {
        display: flex !important;
        text-align: center !important;
        background: #eff3f8 !important;
        flex: 1 1 0% !important;
        align-items: center !important;
        justify-content: center !important;
        height: 100% !important;
        &::before {
          display: none !important;
        }
        &:not(.ant-radio-button-wrapper-checked) {
          border: none !important;
        }
        &.ant-radio-button-wrapper-checked {
          background: #fff !important;
          color: black !important;
          border-radius: 4px !important;
          font-weight: 600 !important;
          border-color: #0e93e5 !important;
          border: 1px solid #0e93e5 !important;
        }
        &:first-child {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
        &:last-child {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }
    &--error {
      font-weight: 500;
      color: red;
    }
  }
}
