.header__area {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  // padding: 12px 0;
  height: 70px;
  display: flex;
  align-items: center;
  background-color: $c-white;
  z-index: 9;

  .logo {
    img {
      height: 30px;

      @media (max-width: 420px) {
        height: 24px;
      }

      @media (max-width: 375px) {
        height: 20px;
      }
    }
  }

  .container {
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &--left {
    display: flex;
    align-items: center;

    &--menu {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 16px;
      align-items: center;
      list-style: none;
      padding-left: 40px;
      margin-bottom: 0;

      li {
        text-align: center;
        position: relative;

        i,
        svg {
          display: none;
        }

        a {
          text-decoration: none;
          width: 100%;
          line-height: 32px;
          font-size: 16px;
          text-align: center;
          font-weight: 500;
          color: $c-gray-light;

          &:hover {
            text-decoration: none;
          }
        }

        .active,
        &:hover {
          color: rgba($c-second, 1);
        }
      }
    }
  }

  &--right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    @media (max-width: 991px) {
      gap: 8px;
    }
  }
}

.navbar__area {
  background-color: $c-white;
  min-height: 70px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 16px;
  box-shadow: rgba(218, 224, 230, 0.2) 0px 4px 10px;

  &--row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;

    &--actions {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .drawer--toggle {
    margin-right: 16px;
  }

  @media (min-width: 992px) {
    .drawer--toggle {
      display: none;
    }
  }
}

.header-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 24px;
  position: sticky;
  top: 0;
  z-index: 99;
  .header__actions {
    background: $c-white;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    display: flex;
    margin-bottom: 0;
    gap: 16px;
    border-radius: 0 0 8px 8px;

    &--content {
      display: flex;
      gap: 8px;
      align-items: center;

      .title {
        color: $c-black;
        font-size: clamp(18px, 3vw, 22px);
        font-weight: 700;
      }

      .subtitle {
        color: $c-text-sec;
        font-size: clamp(14px, 3vw, 18px);
        font-weight: 500;
      }
    }

    &--btns {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}
