.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  gap: 24px;
  img {
    align-self: center;
    width: 150px;
    object-fit: contain;
  }
  &__body {
    width: 100%;
    max-width: 500px;
    background-color: $c-white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 48px 24px;
    .primary {
      width: 100%;
    }
    &--title {
      text-align: center;
      margin-bottom: 16px;
    }
  }
  & .ant-input-affix-wrapper {
    background-color: $c-background;
  }
  &__link {
    color: $c-gray;
    cursor: pointer;
    text-decoration: underline;
  }
}
