.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;

  @media (min-width: 476px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 768px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 992px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 476px) {
    width: 100%;
  }

  @media (min-width: 992px) {
    padding-right: 15px;
    padding-left: 15px;
    max-width: 100%;
  }

  @media (min-width: 1200px) {
    padding-right: 24px;
    padding-left: 24px;
    max-width: 100%;
  }

  @media (min-width: 1391px) {
    padding-right: 24px;
    padding-left: 24px;
    max-width: calc(100% - 300px) !important;
  }
}

.layout {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
