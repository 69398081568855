.heading__area {

  display: flex;
  align-items: center;
  gap: 16px;

  &--icon {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  .bg-primary {
    background-color: $c-primary-light;
    color: $c-primary;
  }
  .bg-gray {
    background-color: $c-gray-light;
    color: $c-gray;
  }

  &--content {
    h2 {
      margin-bottom: 0;
      font-size: clamp(14px, 3vw, 18px);
      font-weight: 700;
      color: $c-text;
    }
  }
}

.head__area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  h4 {
    margin-bottom: 0;
    font-size: clamp(14px, 3vw, 18px);
    font-weight: 600;
    color: $c-text;
  }
}