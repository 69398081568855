.comparison-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin: auto;
  width: 100%;
  .card-body {
    padding: 16px 0;
  }
}

.comparison-table__wrapper {
  .ant-table {
    &-cell-row-hover {
      background-color: inherit !important;
    }

    &-cell-scrollbar {
      border-left: 0 !important;
    }

    &-container {
      border: 1.5px solid $c-primary;
    }

    &-tbody {
      border-radius: 0 !important;

      & > tr {
        &:has(.coverage-header) {
          background-color: $c-primary-background !important;
        }
        & > td {
          &:has(.coverage-header) {
            background-color: $c-primary-background !important;
          }
          &:not(:first-child) {
            border-left: 1.5px solid $c-primary;
          }
          color: $c-text;
          font-size: clamp(12px, 3vw, 14px);
          font-weight: 400;
        }
      }
    }
    &-thead > tr > th,
    &-thead > tr > td {
      background: $c-white;
      &:not(:first-child):not(:empty) {
        border-left: 1.5px solid $c-primary;
      }
      color: $c-text;
      padding: 16px;
      font-weight: 500;

      &::before {
        display: none;
      }
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      cursor: pointer;
      border-start-start-radius: 0px !important;
      border-end-start-radius: 0px !important;
    }
  }

  .ant-select-selector {
    background: $c-white !important;
  }

  .select__wrapper {
    min-width: 300px;
    @media (max-width: 991px) {
      min-width: auto;
    }
  }
}

.coverage-group {
  padding-bottom: 16px;
  &__left {
    font-size: 12px;
    line-height: 14px;
    row-gap: 16px;
    justify-content: space-between;
    color: $c-text-sec;
    &--link {
      display: flex;
      align-items: center;
      column-gap: 10px;
      color: $c-primary;
      font-size: 8px;
      line-height: 12px;
      &:hover {
        cursor: pointer;
      }
    }
    &--icon {
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__row--disable {
    .coverage-card__body {
      display: none;
    }
  }
  &:not(:last-child) {
    border-bottom: 1px solid $c-border;
  }
  &:last-child {
    padding-bottom: 0;
  }
}
.coverage-label {
  &__flex {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
  }
  &__more {
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 12px;
    color: $c-primary;
  }
  &__sub {
    font-size: 12px;
    color: $c-text-sec;
  }
}
.coverage-card {
  border-radius: 4px;
  position: relative;
  min-width: 168px;
  text-align: center;
  &__header {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }
  &__body {
    padding-top: 24px;
  }
  &__content {
    padding: 4px 0;
    font-size: 14px;
    color: $c-text-sec;

    &--title {
      color: $c-text;
    }

    &:not(:last-child):not(:has(.coverage-card__content--base)) {
      border-bottom: 1px solid $c-border;
    }
    &:has(.coverage-card__content--base) {
      .coverage-card__content--title {
        display: none;
      }
    }
  }
  &--highlighted {
    .coverage-card {
      &__body,
      &__header {
        background-color: $c-yellow-light;
      }
      &__header {
        filter: brightness(0.95);
      }
    }
    .material-symbols {
      font-variation-settings: "opsz" 24, "FILL" 1 !important;
    }
  }
}

.coverage-header {
  display: flex;
  width: max-content;
  align-items: center;
  padding: 4px 0;
  column-gap: 12px;
  border-radius: 4px;
  position: sticky;
  left: 0;
  &__icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  &__label {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
}

.insurance-plan {
  display: flex;
  table-layout: auto;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  // flex: 1 !important;
  // padding: 16px 20px;
  border-radius: 4px;
  text-align: center;
  justify-content: space-between;
  // min-width: 200px;
  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  &__icon {
    height: 60px;
    object-fit: contain;
    max-width: 100%;
  }
  &__title {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    min-height: 60px;
    &--light {
      font-size: 12px;
      color: $c-text-sec;
      line-height: 14px;
      font-weight: 300;
      padding-top: 8px;
    }
  }
  &__cost {
    display: flex;
    align-items: center;
    gap: 4px;
    input {
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: $c-primary;
      width: 120px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  &__cost--integer {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: $c-primary;
  }
  &__cost--decimal {
    font-size: 14px;
    vertical-align: super;
  }
  &__cost--period {
    font-size: 12px;
    color: $c-text-sec;
  }
}

.commissioning {
  display: flex;
  box-sizing: border-box;
  gap: 16px;
  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    background: $c-primary-light;
    padding: 8px 12px;
    border-radius: 8px;
    width: 260px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid $c-primary;
    .input__wrapper input {
      background: $c-white;
      height: 32px;
    }
    &:not(:has(.ant-radio-checked)) {
      background: $c-primary-light1;
      border: none;
      .input__wrapper--field {
        opacity: 0.3;
        background: transparent;
        pointer-events: none;
        & * {
          background: transparent;
          pointer-events: none;
        }
      }
    }
    .input__wrapper--field {
      &:not(:has(.ant-input-affix-wrapper)) {
        border: 1px solid $c-gray !important;
        border-radius: 4px;
      }
    }
    .ant-radio-inner {
      height: 12px;
      width: 12px;
    }
    .ant-radio-wrapper {
      .ant-radio-checked {
        .ant-radio-inner {
          border-color: $c-primary;
          background-color: $c-primary;
          &::after {
            transform: scale(0.25);
          }
        }
      }
    }
    .input--transparent {
      .label {
        float: right;
        font-size: 10px;
        line-height: 12px;
        color: $c-text-sec;
        padding-bottom: 4px;
      }
    }
    .label__area {
      align-self: flex-end;
      font-size: 10px;
      line-height: 12px;
      color: $c-text-sec;
    }
    .input__wrapper--field {
      font-size: 12px;
    }
  }
}

.card-hover {
  position: absolute;
  left: 50%;
  bottom: 100%;
  translate: -50% 0;
  width: 140%;
  background: $c-white;
  border: 1px solid $c-border;
  border-radius: 16px;
  z-index: 100;
  overflow: hidden;
  &__header {
    padding: 16px;
    background-color: $c-gray3;
    display: flex;
    gap: 4px;
    align-items: center;
    &--title {
      flex: 1;
      font-size: 16px;
      line-height: 18px;
      text-align: justify;
      padding-right: 24px;
    }
    &--icon {
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
  }
  &__body {
    padding: 16px;
    background-color: $c-white;
    &--title {
      padding-bottom: 16px;
      border-bottom: 1px solid $c-border;
      span {
        display: block;
      }
      span:first-child {
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
        color: $c-text;
      }
      span:not(first-child) {
        font-size: 12px;
        line-height: 16px;
        color: $c-text-sec;
      }
    }
  }
}

.download {
  &__header {
    padding: 16px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
}

.insurance-card {
  display: flex;
  align-items: stretch;
  border: 1px solid $c-light-border;
  border-radius: 8px;
  &:has(input:checked) {
    border: 1px solid $c-primary;
  }

  & > div:not(:last-child) {
    border-right: 1px solid $c-light-border;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 130px;
    img {
      object-fit: contain;
      height: 58px;
      max-width: 90px;
    }
  }
  &__checkbox {
    display: flex;
    align-items: center;
    padding: 0 16px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 0 16px;
    &--name {
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
    &--description {
      display: block;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $c-text-sec;
    }
    &--total {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      span {
        color: $c-text-sec;
      }
    }
    .textarea__custom {
      font-size: 12px;
    }
  }
}

.coverage-group__row--col:last-child {
  .card-hover {
    left: inherit;
    translate: 0;
    right: 0;
  }
}

.modal__footer {
  display: flex;
  column-gap: 16px;
  justify-content: center;
  align-items: center;
}
