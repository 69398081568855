.template-subscription--container {
  & .card-skeleton--container {
    background: $c-white;
    border-radius: 8px;
    position: sticky;
    top: 10px;

    & .card-skeleton--wrapper {
      padding: 24px;
      &__progress-list {
        margin-top: 24px;
        > div {
          padding: 8px 4px;
          border-radius: 4px;
        }
      }
    }
  }

  & .indicator-step {
    font-weight: 400;
    font-size: 12px;
    color: $c-gray-light4;
  }
}

.subscription-step--wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;
  text-align: center;
  color: $c-gray;
}

.subscription-form--wrapper {
  padding: 16px 0;

  & .subscription-form--group-label {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & .form-highlight--wrapper {
    & .ant-input,
    & .ant-select-selector,
    & .ant-picker {
      background-color: $c-white !important;
      border: 1px solid $c-gray !important;
    }
  }

  .waiting_signature {
    background-color: black;
    color: #fff;
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 80px;
    font-weight: 600;
    gap: 6px;
    font-size: 16px;
  }

  .signature_signed {
    background-color: black;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 80px;
    font-weight: 600;
    gap: 6px;
    font-size: 16px;
  }
}

.subscription-proof--wrapper {
  & .subscription-form--custom-upload {
    & .ant-upload-wrapper {
      display: flex;
      flex-direction: column-reverse;
      gap: 4px;
    }
    & .ant-upload-list-item.ant-upload-list-item-done {
      background: $c-background;
    }

    & .ant-upload-list-item-thumbnail.ant-upload-list-item-file {
      background: $c-primary-light;
      border-radius: 8px;
    }
  }
}

.subscription-progress {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in;
  &:hover {
    background-color: $c-background;
  }

  &--header {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-progress-inner {
      width: 40px !important;
      height: 40px !important;
    }
    .ant-progress-text {
      color: $c-primary !important;
      opacity: 0.3;
    }
    &__status {
      width: 12px;
      height: 12px;
      position: absolute;
      border-radius: 999px;
      right: 0;
      border-width: 0.8px;
      border-color: $c-primary;
      border-style: solid;
      background: #fff;
      top: 2px;
      .material-symbols {
        display: block;
      }
    }
  }

  &.complete {
    .subscription-progress {
      &--header {
        .ant-progress-text {
          opacity: 1 !important;
        }
        &__status {
          background: $c-primary;
          color: #fff;
        }
      }
    }
  }
  &--content {
    &__title {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #2f3838;
      margin-bottom: 4px;
    }
    &__description {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #a5aac2;
      &.complete {
        color: $c-primary;
      }
    }
  }
}
.progress_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &--content {
    display: flex;
    justify-content: space-between;
    &__title {
      color: $c-text;
      font-weight: 500;
      display: flex;
      gap: 4px;
      font-size: 16px;
      align-items: center;
    }
    &__percent {
      color: $c-gray;
    }
  }
}
.snippet_wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border-radius: 8px;
  background: $c-primary-light;
  &--title {
    font-size: 18px;
    font-weight: 600;
    color: $c-primary;
  }
  &--description {
    font-weight: 300;
    margin-bottom: 12px;
  }
  &--detail {
    display: flex;
    flex-direction: column;
    &__row {
      display: flex;
      > span:first-child {
        width: 130px;
        font-weight: 600;
      }
    }
    &__divider {
      width: fit-content;
      padding: 10px;
      background: $c-primary-light;
      border-radius: 8px;
      margin: 12px 0;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
.document-list_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &--actions {
    display: flex;
    align-items: center;
    .left_column {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
  }
  &--file-section {
    padding: 16px;
    background: $c-background;
    border-radius: 8px;
    &__title {
      font-size: 16px;
      font-weight: 500;
      color: $c-text;
    }
    &__content {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}

.file_wrapper {
  background: #fff;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: $c-text;
  cursor: pointer;
  gap: 16px;
  &--metadata {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    &__content {
      display: flex;
      align-items: center;
      gap: 12px;
      > div:first-child {
        display: flex;
        align-items: center;
      }

      .__file {
        border-left: 2px solid $c-border;
        position: relative;
        padding-left: 12px;
        align-items: center;
        display: flex;
        gap: 16px;
        width: 100%;
        > div:first-child {
          margin-right: auto;
          width: 100%;
          > div:first-child {
            display: flex;
            align-items: center;
            gap: 8px;
            > span:last-child {
              margin-left: auto;
              color: $c-gray;
            }
          }
        }
      }
    }
  }
  &--action {
    margin-left: auto;
  }
}

.warning-message {
  color: $c-warning;
}
