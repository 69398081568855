.status__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  border-radius: 24px;
  padding: 4px 12px;
  background-color: $c-background;
  width: fit-content;

  span {
    font-size: clamp(12px, 3vw, 14px);
    font-weight: 500;
  }
}

.default--variant {
  background-color: rgba($c-background, 0.8);
  span {
    color: $c-gray-dark;
  }
}

.green--variant {
  background-color: rgba($c-green, 0.1);
  span {
    color: $c-green;
  }
}
.danger--variant {
  background-color: rgba($c-danger, 0.2);
  span {
    color: $c-red;
  }
}
.warning--variant {
  background-color: rgba($c-warning, 0.4);
  span {
    color: $c-orange;
  }
}

// Category

.category__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 24px;
  width: fit-content;

  span {
    font-size: clamp(12px, 3vw, 14px);
    font-weight: 400;
  }
}
.info--variant {
  color: $c-primary;
}
.red--variant {
  color: $c-red;
}
