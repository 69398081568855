.breadcrumb__wrapper {
  &:has(nav ol:empty) {
    display: none;
  }
  & .ant-breadcrumb-link {
    color: $c-gray-light4;
    font-weight: 600;
    font-size: 12px;

    &:hover {
      color: $c-primary;
      background-color: transparent;
    }
  }

  & .ant-breadcrumb-separator {
    color: $c-gray-light4;
    opacity: 0.5;
    font-size: 12px;
  }

  .ant-breadcrumb ol {
    align-items: center;
  }
}
