.composant__area {
    background-color: $c-white;
    border: 1.5px solid $c-primary;
    padding: 8px 16px;
    height: 46px;
    width: max-content;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    color: $c-primary;
    font-size: clamp(12px, 3vw, 16px);

    &.active {
        background-color: $c-primary;
        color: $c-white;
    }

}