.button__action {
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 600;
  padding: 8px 24px;
  margin: auto;
  background: #fff;
  margin-top: 8px;
  cursor: pointer;
  color: #a5aac2;
  border: 1px solid #e6ecf5;
  border-radius: 8px;
}

.locked {
  & input,
  & .input__wrapper--field,
  & .ant-switch,
  & .ant-picker,
  & .ant-radio-group,
  & .ant-select,
  & .ant-slider {
    pointer-events: none;
  }

  & .contact-stepper__content--box--header--action,
  & .button__action {
    display: none;
  }
}
