.fast-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 840px;
  margin: auto;
  & .form-vertical--wrapper {
    padding: 24px 0;
  }
  & .form-vertical--wrapper:not(:last-child) {
    border-bottom: 1px solid $c-gray-light;
  }
}
