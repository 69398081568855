.not-found__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  &--body {
    text-align: center;
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;

    img {
      width: 80%;
      padding-bottom: 40px;
    }
  }
}
