.datepicker {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
.datepicker .datepicker-placeholder {
  font: normal normal medium 34px/24px Rubik;
  color: #969696;
}
.datepicker .datepicker-datepicker {
  margin-top: 8px;
  background-color: #ffffff;
  border-radius: 6px;
  color: #112b75;
  border: 1px solid #eaeaea;
  height: 50px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.datepicker .ant-datepicker {
  font: normal normal medium 34px/24px Rubik;
  color: #112b75;
  font-weight: 700;
}
.datepicker .datepicker-datepicker-error {
  border-color: red;
}
.datepicker .datepicker-error {
  position: absolute;
  bottom: -20px;
  font: normal normal medium 34px/24px Rubik;
  font-weight: 600;
  font-size: 11px;
  color: red;
}