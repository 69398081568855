.description-wrapper {
  .ant-descriptions {
    &-item-content {
      .controlled {
        object-fit: contain;
        max-height: 100px;
        max-width: 200px;
      }
    }
    &-title {
      font-size: 22px;
    }
  }
}
